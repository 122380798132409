import * as React from "react"
import { graphql } from 'gatsby';
import Slider from "react-slick";

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import FeaturedStaff from 'global/FeaturedStaff';
import Image from "components/Image";
import Accordion from "global/Accordion";
import FadeIn from "components/lib/FadeIn";
import Icon from 'components/Icon';



import zparse from "helpers/zparse";
import useWindowDimensions from "hooks/useWindowDimensions";

const StaffDepartment = (props) => {
	const { isMobile } = useWindowDimensions();

	const { edges } = props.data.allStaffDepartment;
	const { edges: pageEdges } = props.data.allContentPage;
	let departments = edges.map(({ node }) => node);
	const pageNodes = pageEdges.map(({ node }) => node);
	let departmentNav = []
	let page = pageNodes[0];
	let staff = []
	if (page?.Staff.length) staff = page?.Staff.map(stf => stf?.object);

	if ((props.providerResponse.data.length > 0 || !!departments !== false) && props.providerStatusText === 'LOADED') {
		departments = props.providerResponse.data;
	}

	departmentNav = departments.map(department => ({ name: department.name, id: department.permalink }));

	return (
		<>
			<Layout>
				<Content {...page} forceOptions={{ pageNav: departmentNav }} />
				{staff.length > 0 && <FeaturedStaff members={staff} />}
				{departments.length && departments.map((department, index) => {
					return (
						<section key={`department-loop-${index}-${department.name}`} id={department.permalink} className={`department-container ${index === 0 && "first-sect"}`}>
							<Accordion title={
								<div className="grid-container">
									<div className="grid-x grid-padding-x align-center">
										<div className="cell small-11 medium-12 top-border-cut">
											<h2 className="accord-title">{department.name} <Icon height="24px" width="30px" className="show-for-small-only" symbol="arrowDown" /></h2>
										</div>
									</div>
								</div>
							} open={!isMobile} disableClick={!isMobile}>
								<div className="staff-card-wrapper">
									<div className="grid-container">
										<div className="grid-x grid-padding-x small-align-center">
											{!!department?.Person.length && department.Person.map((person, index) => {
												return (
													<div key={`card-person-loop-${index}`} className={`cell small-11 medium-${department.Person.length < 4 ? "auto" : "3"} small-text-center`}>
														<div className="staff-card card">
															<div className="card-content">
																<FadeIn direction=" " className="image-holder">
																	<Image className="image" src={person.object.photo} alt="" />
																</FadeIn>
																<div className="name">{person.object.firstName} {person.object.lastName}</div>
																{person.object.position && <div className="position">{person.object.position}</div>}
															</div>
														</div>
													</div>
												)
											})}
											{!!department?.featuredContent.length && (
												<div className="cell small-11 medium-12">
													<div className="fc-slide-wrapper">
														<Slider {...{ dots: true, arrows: false }}>
															{department?.featuredContent.map((fc, index) => {
																return (
																	<div className="fc-slide-inner-wrapper" key={`card-content-loop-${index}`}>
																		<div className="fc-card card">
																			<div className="card-content">
																				<div className="text-holder">
																					<div className="name">{fc.title}</div>
																					{fc.content && <div className="content">{zparse(fc.content)}</div>}
																				</div>
																				<div className="image-holder">
																					<Image className="image" src={fc.image} alt="" />
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})}
														</Slider>
													</div>
												</div>
											)}
											{!!department?.quotes.length && (
												<>
													<div className="cell small-11 medium-12 quote-flx">
														{department?.quotes.map((quote, index) => {
															return (
																<div key={`card-quote-loop-${index}`} className="quote-card card" style={{ backgroundImage: `url(${quote.image})` }}>
																	<div className="card-content">
																		<div className="text-holder">
																			<div className="quote-title">{quote.title}</div>
																			{quote.content && <div className="quote">“{quote.content}“</div>}
																		</div>
																	</div>
																</div>
															)
														})}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</Accordion>
						</section>
					)
				})}
			</Layout>
		</>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="StaffDepartment" apiParams={{ _join: "Person", approved: true, archived: false, deleted: false }}><StaffDepartment {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
{
	allContentPage(
		filter: {uri: {eq: "/staff-directory/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
		  node {
			pageTitle
			meta
			content {
			  main
			}
			headlineBody
			headlineTitle
			headlineImage
			headlineType
			headlineBgColor
			headlineColor
			quicklinks
			featuredSlideText
			featuredSlideUrl
			formID
			Staff {
			  object {
				firstName
				lastName
				permalink
				photo
				position
				bio
			  }
			}
			Category {
			  object {
				id
			  }
			}
		  }
		}
	  }
	allStaffDepartment(filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}) {
	  edges {
		node {
		  name
		  Person {
			object {
			  firstName
			  lastName
			  photo
			  position
			}
		  }
		  permalink
		  featuredContent
		  quotes
		  groups
		}
	  }
	}
}
`